























import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";


export default Vue.extend({

    data() {
        return {
            userManager,
            item: {},
            customer: {},
            users: [],
            types: [],
            print: false,
            name: '',
        };
    },
    computed: {
        availableRoles() {
            const items = [];
            for (const k in this.availableRoleMap) {
                items.push({
                    text: this.availableRoleMap[k],
                    value: k,
                });
            }
            return items;
        },
    },
    watch: {
        '$route'() {
            this.getByIdNotification()
        }
    },
    created() {
        this.initialize();
        this.getAllUser();
        this.getAllGuiderCode();
    },
    methods: {
        async initialize() {
            await this.getByIdNotification()
        },
        getByIdNotification() {
            const id = this.$route.params.id
            apiClient.processGuiderDetail(id).then(res => {
                this.item = res
            })
        },
        getName(value, data, keyData = "id", keyName = "name") {
            if (value) {
                if (Array.isArray(data)) {
                    const length = data.length
                    for (let i = 0; i < length; i++) {
                        if (data[i][keyData] === value) {
                            return data[i][keyName]
                        }
                    }
                }
                return value
            }
            return ""
        },
        async getAllUser() {
            apiClient.userGetAll().then(res => {
                this.users = res.items.map(item => {
                    item.id = String(item.id)
                    return item
                })
            }).catch(e => {
                console.log(e)
            }).finally(() => {

            })
        },
        getAllGuiderCode() {
            const filters = []
            apiClient
                .processGuiderCodeGetAll({
                    options: {},
                    filters,
                })
                .then((res) => {
                    this.types = res.items.map(item => {
                        return {
                            name: item.name,
                            id: String(item.id)
                        }
                    })
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                });
        },
    },
});
